import React from "react";

import "./style.scss";

import { CaretDownOutlined, SearchOutlined } from "../../components";

let oldScroll = 0;

let lastScroll = 0;

const handleScroll = (s) => {
  try {
    return;
    if (new Date().getTime() - lastScroll < 100) return;

    lastScroll = new Date().getTime();

    const rootDiv = document.getElementById("root");
    const screenSize = window.outerHeight;

    if (oldScroll < rootDiv.scrollTop) {
      if (oldScroll < screenSize) {
        rootDiv.scrollBy(0, screenSize);
        oldScroll += screenSize;
      }
    } else {
      if (oldScroll >= screenSize) {
        rootDiv.scrollBy(0, -1 * screenSize);
        oldScroll -= screenSize;
      }
    }
  } catch (e) {}
};

export default () => {
  let ratio = 1;

  try {
    window.visualViewport.addEventListener("resize", (event) => {
      const rootDiv = document.getElementById("model");

      const browserZoomLevel = Math.round(window.devicePixelRatio * 100) / 100;

      if (Number(browserZoomLevel) >= 1) {
        rootDiv &&
          rootDiv.setAttribute("style", "zoom:" + (1 + (1 - browserZoomLevel)));
      }
      return ratio;
    });
  } catch (e) {}

  return (
    <div
      onScroll={(s) => {
        handleScroll(s);
      }}
      id="root"
      className="root"
    >
      <div className="quote-tag">
        <div className="get-a-quote">GET A QUOTE</div>
      </div>
      <div className="header">
        <div>
          <img src="/home-logo.png" />
        </div>

        <div className="header-right">
          <nav className="menu">
            <a href="/home/">HOME</a>
            <a href="/about/">ABOUT</a>
            <a href="/insurance/">INSURANCE</a>
            <a href="/medicare/">MEDICARE</a>
            <a href="/news/">NEWS</a>
            <a href="/careers/">CAREERS</a>
          </nav>
          <SearchOutlined className="search" />
          <div className="licensed-in">
            LICENSED IN
            <CaretDownOutlined className="carrot" />
            <img className="usmap" src="/usa.png" />
          </div>
        </div>
      </div>
      <div className="home">
        <img id="model" className="model" src="/model.png" />

        <div className="home-content">
          <div className="sky"></div>
          <div className="headings">
            <div className="msg1">YOUR COVERAGE STARTS HERE.</div>
            <div className="msg2">
              With the best coverage and health insurance.
            </div>
            <div className="planning-button">START PLANNING</div>
          </div>
        </div>
      </div>
      <div className="about">
        <img className="about-img" src="/about.png" />
      </div>
    </div>
  );
};
